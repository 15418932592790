// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backgroundImage {
  background-image: url(https://websitecontent.ams3.cdn.digitaloceanspaces.com/CheckoutImage.png);
  background-repeat: no-repeat;
  background-size: contain;  /* Ensures the whole image fits within the container */
  background-position: center;
  height: 80%; /* Adjust height to a suitable size */
  width: 100%;  /* Ensures it doesn't overflow */
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/TrustPayments/Checkout.css"],"names":[],"mappings":"AAAA;EACE,+FAA+F;EAC/F,4BAA4B;EAC5B,wBAAwB,GAAG,sDAAsD;EACjF,2BAA2B;EAC3B,WAAW,EAAE,qCAAqC;EAClD,WAAW,GAAG,gCAAgC;EAC9C,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".backgroundImage {\n  background-image: url(https://websitecontent.ams3.cdn.digitaloceanspaces.com/CheckoutImage.png);\n  background-repeat: no-repeat;\n  background-size: contain;  /* Ensures the whole image fits within the container */\n  background-position: center;\n  height: 80%; /* Adjust height to a suitable size */\n  width: 100%;  /* Ensures it doesn't overflow */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
