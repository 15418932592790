import React from 'react';

const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const PromptButton = ({ lesson }) => {
  const handleClick = () => {
    if (!lesson) return;
    const prompt = `Generate a detailed lesson for UK Key Stage 2 students (Upper KS2, Year ${lesson.year_id}). The lesson will be accessed on an online learning platform.

**Requirements**:
1. **Content**: Engaging, detailed, and age-appropriate for children aged 9–11.
2. **Language**: Advance complexity while ensuring it remains age-appropriate.
3. **Images**: Include placeholders with clear descriptions for essential images.
4. **Format**:
    - **Lesson Objectives**: Use first-person language (e.g., "you will learn").
    - **Explanation**: Provide a clear and detailed explanation of the topic.
    - **Scenarios**: Include at least 3 scenarios to demonstrate key concepts.
    - **Summary**: Summarise the key takeaways of the lesson.

**Variables**:
- Lesson Title: ${lesson.title}
- Objectives: ${lesson.objectives}
- Year: ${lesson.year_id}
- Difficulty Level: ${lesson.difficulty_level}
- Lesson ID: ${lesson.id}

**Notes**:
- Ensure formatting is suitable for rich text (no lines or unnecessary formatting).
- Minimum word count: 600 words.
- Limit sums to simple characters.

**Age Reference**:
- Year 5: 9–10 | Year 6: 10–11.

    
    
    ` // Add more dynamic content as needed
    copyToClipboard(prompt);
    alert('Prompt copied to clipboard.');
  };

  return (
    <button onClick={handleClick}>Upper KS2 LESSON Prompt</button>
  );
};

export default PromptButton;
