import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import './ChatApp.css'; // Add custom styles for the chat UI
import Picker from 'emoji-picker-react';

const ChatApp = ({ closeChat }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isStudent, setIsStudent] = useState(false); // State to check if the user is a student
  const [currentUserId, setCurrentUserId] = useState(null);
  const chatWindowRef = useRef(null);
  const chatContainerRef = useRef(null); 
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const userType = localStorage.getItem('usertype');
    const userId = localStorage.getItem('user_id');

    if (userType === 'student') {
      setIsStudent(true);
      setCurrentUserId(userId);
    } else {
      setIsStudent(false);
      setCurrentUserId(null);
      console.warn('Chat is only available for student accounts.');
      return; // Exit early if not a student
    }

    const socket = io('https://whale-app-6qyfq.ondigitalocean.app', {
      auth: {
        token: `Bearer ${token}`,
      },
    });

    const fetchChatHistory = async () => {
      try {
        const response = await fetch('https://whale-app-6qyfq.ondigitalocean.app/get-recent-messages', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();

        const formattedMessages = data.map((msg) => ({
          ...msg,
          isSelf: String(msg.sender_id) === String(userId),
        }));

        setMessages(formattedMessages);
        setTimeout(scrollToBottom, 100); // Scroll to the bottom after loading history
      } catch (error) {
        console.error('Error loading chat history:', error);
      }
    };

    if (isStudent) {
      fetchChatHistory();
    }

    socket.on('receive-message', (msg) => {
      setMessages((prevMessages) => {
        // Check if we are scrolled to the bottom
        const shouldScroll =
          chatWindowRef.current.scrollHeight - chatWindowRef.current.scrollTop ===
          chatWindowRef.current.clientHeight;

        const newMessages = [
          ...prevMessages,
          { ...msg, isSelf: String(msg.sender_id) === String(userId) },
        ];

        // Auto-scroll if we were already at the bottom
        if (shouldScroll) {
          setTimeout(scrollToBottom, 100);
        }

        return newMessages;
      });
    });

    socket.on('message-flagged', (data) => {
      const systemMessage = {
        username: 'System',
        message: data.message,
        isSelf: true,
        isSystem: true,
      };

      setMessages((prevMessages) => [...prevMessages, systemMessage]);

      setTimeout(() => {
        setMessages((prevMessages) => prevMessages.filter((msg) => msg !== systemMessage));
      }, 5000);
    });

    return () => {
      socket.disconnect(); // Cleanup socket connection
    };
  }, [isStudent]);

  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  const sendMessage = () => {
    if (input.trim() && isStudent) {
      const token = localStorage.getItem('accessToken');
      const currentUsername = localStorage.getItem('username');

      const msg = {
        message: input,
        userId: currentUserId,
        username: currentUsername,
        sender_id: currentUserId,
      };

      const socket = io('https://whale-app-6qyfq.ondigitalocean.app/', {
        auth: {
          token: `Bearer ${token}`,
        },
      });

      socket.emit('send-message', msg);
      setInput('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendMessage();
    }
  };

  const handleEmojiClick = (emojiData, event) => {
    // `emojiData.emoji` will be the actual emoji character
    setInput((prevInput) => prevInput + emojiData.emoji);
    setShowEmojiPicker(false); // close the picker after selection
  };


  useEffect(() => {
    if (!isStudent) return; // Only run if the user is a student
  
    const chatContainer = chatContainerRef.current;
    const resizer = document.querySelector(".chat-resizer");
    if (!chatContainer || !resizer) return;
  
    let isResizing = false;
    let startX, startY, startWidth, startHeight, startLeft, startTop;
  
    const handleMouseDown = (e) => {
      isResizing = true;
      startX = e.clientX;
      startY = e.clientY;
      startWidth = chatContainer.offsetWidth;
      startHeight = chatContainer.offsetHeight;
      startLeft = chatContainer.offsetLeft;
      startTop = chatContainer.offsetTop;
  
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };
  
    const handleMouseMove = (e) => {
      if (!isResizing) return;
  
      const diffX = e.clientX - startX;
      const diffY = e.clientY - startY;
  
      let newWidth = startWidth - diffX;
      let newHeight = startHeight - diffY;
  
      // Limit sizes
      newWidth = Math.max(250, Math.min(newWidth, window.innerWidth * 0.9));
      newHeight = Math.max(300, Math.min(newHeight, window.innerHeight * 0.9));
  
      chatContainer.style.width = `${newWidth}px`;
      chatContainer.style.height = `${newHeight}px`;
  
      // Update position so resizing happens from top-left
      chatContainer.style.left = `${startLeft + diffX}px`;
      chatContainer.style.top = `${startTop + diffY}px`;
    };
  
    const handleMouseUp = () => {
      isResizing = false;
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  
    resizer.addEventListener("mousedown", handleMouseDown);
  
    return () => {
      resizer.removeEventListener("mousedown", handleMouseDown);
    };
  }, [isStudent]);
  
  
  


  if (!isStudent) {
    return (
      <div className="chat-container">
        <div className="chat-header">
        <button onClick={closeChat} className="chat-close-button" aria-label="Close chat">
  ✖
</button>
          <h4>Chat Unavailable</h4>
        </div>
        <div className="chat-window">
          <p style={{ textAlign: 'center', marginTop: '20px' }}>
            Chat is only available for student accounts.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="chat-container" ref={chatContainerRef}>
      {/* Header */}
      <div className="chat-header">
      <div className="chat-resizer">
  {/* Optionally add a visual indicator, like diagonal lines or an icon */}
  <div className="resize-handle" />
</div>
<button onClick={closeChat} className="chat-close-button" aria-label="Close chat">
  ✖
</button>
        <img
          src="https://websitecontent.ams3.cdn.digitaloceanspaces.com/RocketChatv1Logo.png"
          alt="RocketChat v1"
          className="chat-header-logo"
        />
      </div>
  
      {/* Chat Window */}
      <div className="chat-window" ref={chatWindowRef}>
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`chat-message ${
              msg.isSelf ? 'chat-message-self' : 'chat-message-other'
            } ${msg.isSystem ? 'chat-message-system' : ''}`}
            style={{ color: msg.isSystem ? 'red' : 'inherit' }}
          >
            <strong>{msg.username}:</strong> {msg.message}
          </div>
        ))}
      </div>
  
      {/* Input Container */}
      <div className="chat-input-container">
        {/*
          Input field
        */}
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message..."
          className="chat-input"
          onKeyPress={handleKeyPress}
        />
  
        {/*
          Emoji button
        */}
        <button
          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
          className="chat-emoji-button"
        >
          😀
        </button>
  
        {/* Emoji Picker */}
        {showEmojiPicker && (
          <div className="emoji-picker-container">
            <Picker onEmojiClick={handleEmojiClick} />
          </div>
        )}
  
        {/*
          Send button
        */}
        <button onClick={sendMessage} className="chat-send-button">
          Send
        </button>
      </div>
    </div>
  );  
};

export default ChatApp;

