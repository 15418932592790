import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Typography, CircularProgress } from '@mui/material';

const QuizAdmin = ({ selectedLessonId }) => {
  const [jsonOutput, setJsonOutput] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    axios
      .get(`https://orca-app-mna7u.ondigitalocean.app/admin/quiz/${selectedLessonId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        const questions = res.data.questions || res.data;
        if (Array.isArray(questions)) {
          setJsonOutput(JSON.stringify(questions, null, 2));
        } else {
          setJsonOutput('');
        }
      })
      .catch((err) => {
        console.error(err);
        setMessage('Error retrieving quiz data. Please check the backend logs.');
      });
  }, [selectedLessonId]);

  const saveChanges = () => {
    try {
      const data = JSON.parse(jsonOutput); // Ensure valid JSON
      const accessToken = localStorage.getItem('accessToken');
      axios
        .put(`https://orca-app-mna7u.ondigitalocean.app/admin/quiz/${selectedLessonId}`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((res) => {
          setMessage('Changes saved successfully.');
        })
        .catch((err) => {
          console.error(err);
          setMessage('Error saving changes. Please check the backend logs.');
        });
    } catch (err) {
      console.error('Invalid JSON', err);
      setMessage('Invalid JSON. Please fix any syntax errors.');
    }
  };

  const validateJSON = () => {
    try {
      JSON.parse(jsonOutput);
      setMessage('JSON is valid.');
    } catch (err) {
      console.error('Invalid JSON for validation', err);
      setMessage('Invalid JSON. Please fix any syntax errors.');
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
      <TextField
        style={{ width: '80%' }}
        multiline
        rows={20}
        variant="outlined"
        value={jsonOutput}
        onChange={(e) => setJsonOutput(e.target.value)}
      />

      <div>
        <Button onClick={saveChanges}>Save Changes</Button>
        <Button onClick={validateJSON}>Validate JSON</Button>
      </div>

      {message && (
        <Typography
          color={message.includes('Error') || message.includes('Invalid') ? 'error' : 'primary'}
        >
          {message}
        </Typography>
      )}
    </div>
  );
};

export default QuizAdmin;
