import React, { useState, useEffect, useRef } from 'react';
import {
  createTheme,
  ThemeProvider,
  CssBaseline,
  Grid,
  Paper,
  Box,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Switch,
  FormControlLabel,
  Button,
  TextField,
} from '@mui/material';
import PaymentForm from './PaymentForm';
import useApi from '../Utils/useApi';
import './Checkout.css';

const defaultTheme = createTheme();

const Checkout = () => {
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState(new Set());
  const [studentSubscriptions, setStudentSubscriptions] = useState({});
  const [selectedNumberOfStudents, setSelectedNumberOfStudents] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [requestReference, setRequestReference] = useState('');
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [discountCode, setDiscountCode] = useState('');
  const [isValidCode, setIsValidCode] = useState(false);
  const [autoRenew, setAutoRenew] = useState(true); // Default to auto-renew enabled
  const api = useApi();
  const isFetched = useRef(false);

  const fixedPrices = {
    '1 Student': { Month: 20, Year: 149 },
    '2 Students': { Month: 30, Year: 179 },
    'Up to 5 Students': { Month: 40, Year: 199 },
  };

  const studentOptions = [
    { label: 'Single Student', detail: '1 Student', id: '1 Student' },
    { label: 'Duo', detail: '2 Students', id: '2 Students' },
    { label: 'Family Pack', detail: 'Up to 5 Students', id: 'Up to 5 Students' },
  ];

  const handleRegister = () => {
    window.location.href = "/StudentRegistration";
  };

  const getStudentLimit = () => {
    switch (selectedNumberOfStudents) {
      case '1 Student':    return 1;
      case '2 Students':   return 2;
      case 'Up to 5 Students': return 5;
      default:             return Infinity;
    }
  };

  useEffect(() => {
    const fetchStudentsAndSubscriptions = async () => {
      const parent_id = localStorage.getItem('user_id');
      if (!parent_id) {
        console.error('No parent ID found');
        return;
      }
      try {
        const res = await api.get(`/user/students/${parent_id}`);
        setStudents(res.data);

        const subscriptionPromises = res.data.map((student) =>
          api.get(`/subscription/subscription/user/${student.id}`)
        );
        const subscriptionResults = await Promise.all(subscriptionPromises);

        const subscriptionData = {};
        subscriptionResults.forEach((result, index) => {
          subscriptionData[res.data[index].id] = result.data;
        });

        setStudentSubscriptions(subscriptionData);
        isFetched.current = true;
      } catch (err) {
        console.error('Error fetching students or subscriptions:', err);
      }
    };

    if (!isFetched.current) {
      fetchStudentsAndSubscriptions();
    }
  }, [api]);

  const handleSelectNumberOfStudents = (option) => {
    setSelectedNumberOfStudents(option);
    setSelectedSubscription(null);
    setSelectedStudents(new Set());

    const options = {
      '1 Student': [
        { id: 'Month', title: '1 Month', price: 20 },
        { id: 'Year', title: '1 Year', price: 149 }
      ],
      '2 Students': [
        { id: 'Month', title: '1 Month', price: 30 },
        { id: 'Year', title: '1 Year', price: 179 }
      ],
      'Up to 5 Students': [
        { id: 'Month', title: '1 Month', price: 40 },
        { id: 'Year', title: '1 Year', price: 199 }
      ],
    };

    setSubscriptionOptions(options[option] || []);
  };

  useEffect(() => {
    if (selectedNumberOfStudents && selectedSubscription) {
      const prices = fixedPrices[selectedNumberOfStudents];
      let price = 0;

      switch (selectedSubscription) {
        case 'Month':
          price = prices.Month;
          break;
        case 'Year':
          price = prices.Year;
          break;
        default:
          console.error("Invalid subscription ID");
      }

      // Apply 10% discount if valid
      const finalPrice = isValidCode ? Math.floor(price * 0.9) : price;
      setSelectedPrice(price);
      setDiscountedPrice(finalPrice);
    }
  }, [selectedNumberOfStudents, selectedSubscription, isValidCode]);

  const handleSelectSubscription = (subscriptionId) => {
    setSelectedSubscription(subscriptionId);
  };

  const handleStudentSelectionChange = (studentId) => {
    const newSelection = new Set(selectedStudents);
    if (newSelection.has(studentId)) {
      newSelection.delete(studentId);
    } else {
      if (newSelection.size < getStudentLimit()) {
        newSelection.add(studentId);
      } else {
        console.log("Selection limit reached.");
      }
    }
    setSelectedStudents(newSelection);
  };

  const handleApplyDiscount = () => {
    if (discountCode.trim().toLowerCase() === 'L30nI5Th3M0stHands0m#deV3l0per'.toLowerCase()) {
      setIsValidCode(true);
      const discount = selectedPrice * 0.1; // 10% discount
      setDiscountedPrice(Math.floor(selectedPrice - discount));
      alert("Discount code applied successfully! 🎉");
    } else {
      setIsValidCode(false);
      setDiscountedPrice(selectedPrice); // Reset to original price
      alert("Invalid discount code. Please try again.");
    }
  };

  const handleBuyNow = async () => {
    const selectedStudentIds = Array.from(selectedStudents);
    if (selectedStudentIds.length === 0 || !selectedSubscription) {
      alert("Please select at least one student and a subscription.");
      return;
    }

    try {
      const postData = {
        child_ids: selectedStudentIds.join(','),
        subscription_type: selectedSubscription,
        amount: discountedPrice,
        auto_renew: autoRenew,
      };

      const response = await api.post('/payment/pending-subscriptions', postData, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
      });

      const { request_reference } = response.data;
      if (request_reference) {
        setRequestReference(request_reference);
      } else {
        throw new Error('No request reference returned');
      }
    } catch (error) {
      console.error('Failed to create pending subscription:', error);
      alert("Failed to initiate payment process. Please try again.");
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />

      {/*
        1) Use `minHeight: '100vh'` (instead of a fixed `height`)
        so the container can grow as content extends.
        2) `backgroundSize: 'cover'` means it will scale to fill 
           the entire Box, preserving aspect ratio. If the user 
           scrolls beyond the original viewport, the background 
           extends because the Box is taller.
      */}
      <Box
        sx={{
          minHeight: '100%',
          backgroundImage: 'url("https://websitecontent.ams3.cdn.digitaloceanspaces.com/CheckoutBG.png")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Grid 
          container 
          sx={{ 
            minHeight: '100vh', // Make sure the Grid also extends
            padding: 0 
          }}
        >
          {/* LEFT SIDE with the smaller image */}
          <Grid
            item
            xs={12}
            sm={4}
            md={7}
            sx={{
              backgroundImage:
                'url("https://websitecontent.ams3.cdn.digitaloceanspaces.com/CheckoutImages.png")',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
            }}
          />

          {/* RIGHT SIDE - the interactive area */}
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={0}
            square
            sx={{
              backgroundColor: '#f5f5f5',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                mx: 4,
                pb:6,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
                Select Number of Students
              </Typography>
              <Grid container spacing={2} sx={{ mt: 3 }}>
                {studentOptions.map((option) => (
                  <Grid item xs={12} sm={6} md={4} key={option.id}>
                    <Card
                      variant="outlined"
                      sx={{
                        backgroundColor:
                          selectedNumberOfStudents === option.id ? 'lightgray' : 'white',
                      }}
                    >
                      <CardActionArea onClick={() => handleSelectNumberOfStudents(option.id)}>
                        <CardContent>
                          <Typography variant="h5" component="div">
                            {option.label}
                          </Typography>
                          <Typography variant="body2">{option.detail}</Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              {selectedNumberOfStudents && (
                <>
                  <Typography component="h2" variant="h5" sx={{ mt: 4 }}>
                    Select Subscription Length
                  </Typography>
                  <Grid container spacing={2} sx={{ mt: 3 }}>
                    {subscriptionOptions.map((option) => (
                      <Grid item xs={12} sm={6} md={4} key={option.id}>
                        <Card
                          variant="outlined"
                          sx={{
                            backgroundColor:
                              selectedSubscription === option.id ? 'lightgray' : 'white',
                          }}
                        >
                          <CardActionArea onClick={() => handleSelectSubscription(option.id)}>
                            <CardContent>
                              <Typography variant="h5" component="div">
                                {option.title}
                              </Typography>
                              <Typography variant="body2">£{option.price}</Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}

              {selectedSubscription && (
                <>
                  <Typography variant="h6" sx={{ mt: 4 }}>
                    Select Students (up to {getStudentLimit()})
                  </Typography>
                  <Grid container justifyContent="center" sx={{ mt: 4 }}>
                    <Grid item xs={12} sm={6} md={4}>
                      {students.map((student) => (
                        <FormControlLabel
                          key={student.id}
                          control={
                            <Switch
                              checked={selectedStudents.has(student.id)}
                              onChange={() => handleStudentSelectionChange(student.id)}
                              disabled={
                                !selectedStudents.has(student.id) &&
                                selectedStudents.size >= getStudentLimit()
                              }
                            />
                          }
                          label={`${student.firstname} ${student.lastname}`}
                          sx={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                            width: '100%',
                          }}
                        />
                      ))}
                    </Grid>
                  </Grid>
                </>
              )}

              {selectedSubscription && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={autoRenew}
                      onChange={() => setAutoRenew(!autoRenew)}
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body1">
                      Auto-renew subscription (You can cancel anytime)
                    </Typography>
                  }
                  sx={{ mt: 2 }}
                />
              )}

              <Button
                color="inherit"
                variant="outlined"
                onClick={handleRegister}
                sx={{ marginTop: 2, marginBottom: 2 }}
              >
                Register More Students
              </Button>

              <Typography component="h2" variant="h5" sx={{ mt: 4 }}>
                Enter Voucher Code
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <TextField
                  label="Discount Code"
                  variant="outlined"
                  value={discountCode}
                  onChange={(e) => setDiscountCode(e.target.value)}
                  sx={{ mr: 2 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleApplyDiscount}
                  disabled={!discountCode.trim()}
                >
                  Apply
                </Button>
              </Box>

              {isValidCode && (
                <Typography variant="body2" color="green" sx={{ mt: 1 }}>
                  🎉 Discount applied! Your total has been updated.
                </Typography>
              )}

              <Typography variant="h6" sx={{ mt: 4 }}>
                Total Price: £{discountedPrice}
              </Typography>

              {selectedSubscription && selectedStudents.size > 0 && !requestReference && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleBuyNow}
                  sx={{ mt: 3 }}
                >
                  Buy Now
                </Button>
              )}

              {requestReference && (
                <PaymentForm
                  domain="https://payments.securetrading.net"
                  amount={discountedPrice}
                  currency="GBP"
                  requestReference={requestReference}
                  autoRenew={autoRenew}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default Checkout;
