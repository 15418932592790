import React from 'react';

const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const PromptButton = ({ lesson }) => {
  const handleClick = () => {
    if (!lesson) return;
    const prompt = `I need you to generate a detailed lesson for UK school-aged children, specifically in Year ${lesson.year_id}. The content will be accessed via an online learning platform.

**Requirements**:
1. **Content**: Rich, well-structured, and focused on teaching the topic clearly and concisely.
2. **Language**: Age-appropriate for students aged 11–16.
3. **Images**: Only include placeholders with clear, descriptive instructions for essential images (for artists or generative AI). Avoid images unless necessary.
4. **Format**:
    - **Lesson Objectives**: Use first-person language (e.g., "you will learn").
    - **Explanation**: Clearly explain the topic.
    - **Scenarios**: Include at least 3 scenarios (Scenario 1, Scenario 2, Scenario 3) to demonstrate key concepts.
    - **Summary**: Summarise what the student has learned.
    - **Language**: Exclusively use british spellings.
5. **Word Count**: Minimum of 600 words.
6. **Variables**:
    - Lesson Title: ${lesson.title}
    - Objectives: ${lesson.objectives}
    - Year: ${lesson.year_id}
    - Difficulty Level: ${lesson.difficulty_level}
    - Lesson ID: ${lesson.id}

**Note**:
- Keep formatting suitable for rich text (no lines, numbering, or excessive symbols).
- Display sums using basic characters only.

**Age Reference**:
- Year 7: 11–12 | Year 8: 12–13 | Year 9: 13–14 | Year 10: 14–15 | Year 11: 15–16.
`
    
    // Add more dynamic content as needed
    copyToClipboard(prompt);
    alert('Prompt copied to clipboard.');
  };

  return (
    <button onClick={handleClick}>KS3 & KS4 LESSON Prompt</button>
  );
};

export default PromptButton;
