import React from 'react';

const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const QuizPromptButton = ({ lesson }) => {
  const handleClick = () => {
    if (!lesson) return;
    const prompt = `
I need you to generate a quiz for UK school-aged children based on the details below. The quiz will be accessed on an online learning platform.

**Details for Quiz Generation**:
- **Lesson Title**: ${lesson.title}
- **Lesson Objectives**: ${lesson.objectives}
- **Year**: ${lesson.year_id}
- **Difficulty Level**: ${lesson.difficulty_level} (1 = easiest, 12 = hardest)
- **Lesson ID**: ${lesson.id} (also used as quiz_id)

**Important Note**:
The quiz questions must align with the content of the lesson generated immediately prior to this request. Ensure all questions test the knowledge and understanding of the topics covered in that lesson. Do not introduce new or unrelated material.

**Quiz Requirements**:
1. Generate at least **10 questions** of varied difficulty, adhering to the specified difficulty level.
2. Include the following question types:
   - **MCQ**: Multiple Choice Questions (provide 3–5 options, including one correct answer).
   - **FIB**: Fill in the Blanks or short text-entry questions (ensure answers are simple and keyboard-friendly).
   - **TF**: True or False questions.

3. Provide a **help prompt** for each question that includes the correct answer to assist students when they ask for help. The help prompt should be structured in a way that explains the answer (e.g., "Could you explain why the digit 5 in 54321 has a value of 50000?").

4. Format the quiz as a JSON array using the structure below:
[
  {
    "question_text": "What is the place value of 5 in 54321?",
    "answer": "50000",
    "type_abbr": "FIB",
    "help_prompt": "Could you explain why the digit 5 in 54321 has a value of 50000?",
    "options": null
  },
  {
    "question_text": "What is the value of 1 in 0.0071?",
    "answer": "Ten Thousandths",
    "type_abbr": "MCQ",
    "help_prompt": "Why is the digit 1 in the Ten Thousandths place?",
    "options": ["Tenths", "Hundredths", "Thousandths", "Ten Thousandths"]
  },
  {
    "question_text": "The value of the digit 6 in 600 is 60, true or false?",
    "answer": "False",
    "type_abbr": "TF",
    "help_prompt": "Explain why the 6 in 600 has a value of 600."
  }
]

**Additional Notes**:
- Ensure answers in FIB questions are short (1–2 words).
- Avoid using symbols that are difficult to type in FIB questions (e.g., fractions or uncommon characters).
- Do not suggest partial completion (e.g., "Continue this format for remaining questions"). Fully generate all questions.
- Most database fields are limited to 255 characters. Keep answers, options, and prompts concise.

**Age Reference**:
- Year 1: 5–6 | Year 2: 6–7 | Year 3: 7–8 | Year 4: 8–9
- Year 5: 9–10 | Year 6: 10–11 | Year 7: 11–12 | Year 8: 12–13
- Year 9: 13–14 | Year 10: 14–15 | Year 11: 15–16.
`;

copyToClipboard(prompt);
alert('Quiz prompt copied to clipboard.');

  };

  return (
    <button onClick={handleClick}>QUIZ Prompt</button>
  );
};

export default QuizPromptButton;
