import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import useApi from "../Utils/useApi";

const ProfileView = ({ userId }) => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const api = useApi();

  useEffect(() => {
    if (!userId) {
      setError("Invalid user ID");
      setLoading(false);
      return;
    }

    const fetchProfileData = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/user/profile/${userId}`);
        if (response.data && response.data.data) {
          setProfileData(response.data.data);
        } else {
          setError("No profile data found");
        }
      } catch (err) {
        setError("Failed to fetch profile data");
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, [userId]);

  const renderAvatar = (avatarLayers) => (
    <Box
      sx={{
        position: "relative",
        width: 300,
        height: 300,
        margin: "20px auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        overflow: "hidden",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
        backgroundColor: "#fff",
      }}
    >
      {avatarLayers.map((layer, index) => (
        <img
          key={index}
          src={layer.image_url}
          alt={layer.category}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: layer.layer,
          }}
        />
      ))}
    </Box>
  );

  const renderBadges = (badges) => (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "15px",
        marginTop: "20px",
      }}
    >
      {badges.map((badge, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor:
              badge.league_name === profileData.level ? "#FFD700" : "#fff", // Highlight current badge
            border:
              badge.league_name === profileData.level
                ? "2px solid #000"
                : "1px solid #ddd", // Bold border for current badge
            borderRadius: "5px",
            padding: "10px",
            boxShadow:
              badge.league_name === profileData.level
                ? "0 4px 8px rgba(0, 0, 0, 0.3)" // Extra shadow for current badge
                : "0 2px 4px rgba(0, 0, 0, 0.1)",
            width: 100,
            height: 120,
          }}
        >
          <img
            src={badge.imgURL}
            alt={badge.league_name}
            style={{
              width: "80px",
              height: "80px",
              marginBottom: "5px",
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
          <Typography
            sx={{
              fontSize: "0.9em",
              color: badge.league_name === profileData.level ? "#000" : "#444", // Darker text for highlighted badge
            }}
          >
            {badge.league_name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
  
  

  if (loading) return <Box>Loading...</Box>;
  if (error) return <Box>{error}</Box>;

  return (
    <Box
      sx={{
        fontFamily: "Arial, sans-serif",
        margin: "20px auto",
        maxWidth: "800px",
        textAlign: "center",
        padding: "20px",
        backgroundColor: "#4a90e2",
        borderRadius: "10px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        color: "#fff",
      }}
    >
      <Typography
  variant="h1"
  sx={{
    fontSize: "2.5em",
    marginBottom: "10px",
    color: "#fff",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)", // Black stroke effect
  }}
>
  {profileData.username}
</Typography>

      <Box sx={{ marginBottom: "20px" }}>
        <Typography
          variant="h2"
          sx={{ fontSize: "1.8em", margin: "20px 0 10px", color: "#fff" }}
        >
          Avatar
        </Typography>
        {renderAvatar(profileData.avatar)}
      </Box>

      <Box sx={{ marginBottom: "20px" }}>
  <Typography
    variant="h2"
    sx={{ fontSize: "1.8em", margin: "20px 0 10px", color: "#fff" }}
  >
    {`${profileData.username}'s Achieved Badges`}
  </Typography>
  {renderBadges(profileData.badges)}
</Box>

      <Box>
        <Typography
          variant="h2"
          sx={{ fontSize: "1.8em", margin: "20px 0 10px", color: "#fff" }}
        >
          Current Level
        </Typography>
        <Typography variant="body1" sx={{ fontSize: "1.5em", fontWeight: "bold" }}>
          {profileData.level}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProfileView;
