import React from 'react';

const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const PromptButton = ({ lesson }) => {
  const handleClick = () => {
    if (!lesson) return;
    const prompt = `I need you to create a lesson for UK Key Stage 1 students (Years ${lesson.year_id}). The content will be accessed on an online learning platform.

**Requirements**:
1. **Content**: Clear, engaging, and suitable for children aged 5–7.
2. **Images**: Only include placeholders with detailed descriptions for essential images. Avoid unnecessary visuals.
3. **Language**: Simple and easy to understand for the age group.
4. **Format**:
    - **Lesson Objectives**: Use first-person language (e.g., "you will learn").
    - **Explanation**: Explain the topic clearly and concisely.
    - **Scenarios**: Provide relevant examples to help understanding.
    - **Summary**: Recap the key points of the lesson.
    - **Language**: Use British English spellings.

**Variables**:
- Lesson Title: ${lesson.title}
- Objectives: ${lesson.objectives}
- Year: ${lesson.year_id}
- Difficulty Level: ${lesson.difficulty_level}
- Lesson ID: ${lesson.id}

**Notes**:
- Keep formatting suitable for rich text (no lines, numbering, or complex symbols).
- Display sums using simple characters only.

**Age Reference**:
- Year 1: 5–6 | Year 2: 6–7.



    
    
    ` // Add more dynamic content as needed
    copyToClipboard(prompt);
    alert('Prompt copied to clipboard.');
  };

  return (
    <button onClick={handleClick}>KS1 LESSON Prompt</button>
  );
};

export default PromptButton;
